import React from "react";

const features = [
  {
    name: "Facial Recognition",
    description: "Eliminate buddy clock ins with state-of-the-art facial recognition.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>)
  },
  {
    name: "Automated timesheet calculations",
    description: "Let us do the heavy lifting by automatically calculating how much regular and overtime each employee has worked.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>)
  },
  {
    name: "Time Clock Kiosk",
    description: "A shared kiosk (tablet) for multiple employees to clock in and out from.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>)
  },
  {
    name: "Mobile App",
    description: "Enable employees to use their own mobile phone to clock in and out, book leave and more.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>)
  },
  {
    name: "GPS Clock Ins + Geofences",
    description: "Geolocate each employee clock in with the option of restricting to defined geographical areas.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>)
  },
  {
    name: "Track Time on Jobs",
    description: "Allocate time against jobs and run automated job timing and costing reports.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /></svg>)
  },
  {
    name: "Powerful Integrations",
    description: "Payroll integrations including Xero, Sage, MoneySoft and BrightPay.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" /></svg>)
  },
  {
    name: "Manage Employee Time Off",
    description: "Approve employee leave requests and track employee time off via your web dashboard.",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" /></svg>)
  },
  {
    name: "And much more...",
    description: "Rota planner, Job scheduling, Job Management, share HR documents, instant messenger and more...",
    icon: (<svg className="w-12 h-12 text-highlight-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" /></svg>)
  }
]


export default function FeatureBlock() {
  return (
    <div className="relative bg-primary-500 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-100 px-4 text-center sm:px-6 lg:px-8">
        <h2 className="text-base font-semibold tracking-wider text-white uppercase">Feature Overview</h2>
        <p className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
          Making employee <span className="text-highlight-500"> time and attendance</span> a breeze
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-white">
          TimeKeeper solves problems that costs your business time and money.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-primary-500 rounded-md shadow-lg">
                        {feature.icon}
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-white tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
