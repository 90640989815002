import React from 'react';

const Container = ({ children }) => (
	<div className="relative bg-gray-50 overflow-hidden">
		<div data-todo-x-data="{ open: false }" className="relative pt-6 pb-8 sm:pb-8 md:pb-12 lg:pb-20 xl:pb-24">
			{children}
		</div>
	</div>
)

export default Container;