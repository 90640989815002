import React, {useState, Fragment} from "react"
import Container from './container';

import LogoCloud from './logo-cloud';

import thumbnailSrc from '../images/video-thumbnail.jpg';

import FreeTrialButton from './free-trial-button';

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <Fragment>
        {showVideo &&
          <div style={{
              position: 'fixed',
              top:0,
              left:0,
              width: '100vw',
              height: '100vh',
              zIndex:10,
              
           }} onClick={() => setShowVideo(false)}> 
              <div className="flex w-full h-full justify-center items-center" style={{backgroundColor: '#171717', zIndex:11, opacity: 1 }}>
                 <div style={{
                  position: 'absolute',
                  color: '#fff',
                  backgroundColor: 'rgba(160, 174, 192, 1)',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  right:'10%',
                  top:'10%',
                  padding: '5px'
               }}>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </div>
                <iframe
                  width="70%" 
                  height="70%"
                  src="https://www.youtube-nocookie.com/embed/Nq31i3n7XfA?si=WehKhiJ9xxRIZCl6&autoplay=1&mute=1"
                  title="Introducing TimeKeeper"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </div>
          </div>
      }
      <Container>
        <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-left md:mx-auto md:max-w-2xl lg:col-span-7 lg:text-left">
              <h2 className="text-4xl sm:text-5xl font-black leading-snug sm:leading-tighter tracking-tight text-gray-900">
               Simple <span className="text-highlight-500">employee timesheet</span> app for your business
              </h2>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                The paperless way to manage timesheets, rotas, time off and jobs. <br /> Works on your everyday devices.
              </p>
              <FreeTrialButton />
              <div className="mt-4 sm:flex italic sm:justify-left md:mt-4 text-base text-gray-500">No credit card required.</div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-5 lg:flex lg:items-center">
              <svg className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784">
                <defs>
                  <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-xl">
                <button onClick={()=> setShowVideo(true)} type="button" className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                  <img className="w-full" src={thumbnailSrc} alt="Introducing TimeKeeper Video" />
                  <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                    <svg className="h-20 w-20 text-white" fill="currentColor" viewBox="0 0 84 84">
                      <circle className="text-highlight-500" opacity="0.9" cx="42" cy="42" r="42" fill="currentColor" />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <LogoCloud />
          </div>
        </main>
      </Container>
    </Fragment>
  )
}

export default Hero;
