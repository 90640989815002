import React from "react"

import LeftFeature from './left-feature';
import RightFeature from './right-feature';
import FeaturePararaph from './feature-paragraph'

import clockInSrc from '../images/clockin.png';
import timesheetSrc from '../images/my-timesheet.png'
import leaveRequestSrc from '../images/leave-request.png'
import accountancyIntegrationsSrc from '../images/accountancy-integrations.svg';
import rotaPlannerSrc from '../images/rota-planner.png';
import wallCalendarSrc from '../images/leave-calendar.svg';
import whosInSrc from '../images/whos-in.svg';
import employeeStatusSrc from '../images/employee-status.svg';
import geofenceSrc from '../images/geofence.png';
import jobSchedulerSrc from '../images/job-scheduler.svg';
import instantMessengerSrc from '../images/instant-messenger-mobile.png';

const FeatureSideBySide = () => (
	<div>
		<div className="bg-gray-50 overflow-hidden">
		  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
		    <LeftFeature 
		    	sectionTitle="Facial Recognition" 
		    	title="Eliminate buddy clock entries" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          With traditional time and attendance systems, employees can easily fool the system by clocking in for their colleagues.
				        </FeaturePararaph>
				        <FeaturePararaph>
				          Well, with TimeKeeper, this problem is eliminated with the use of facial recognition to prevent any buddy clock ins going on.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={clockInSrc}
		    	imgAlt="Showing Man Using Facial Recognition On Clock In">
		    </LeftFeature>
		    <RightFeature 
		    	sectionTitle="Automatic Timesheets" 
		    	title="No more manual timesheet calculations" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          Are you wasting hours totting up timesheets, retyping data for employee payroll, or manually deducting breaks?
				        </FeaturePararaph>
				        <FeaturePararaph>
				          All timesheets are automatically calculated by TimeKeeper so you can access and report in real time on any device. 
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={timesheetSrc}
		    	imgAlt="TimeKeeper Timesheets"
		    />
		    <LeftFeature 
		    	sectionTitle="Leave Management" 
		    	title="Record and track your employee leave" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          Still using leave request sheets? Staff asking you how many holidays they have left or sick days they have used?
				        </FeaturePararaph>
				        <FeaturePararaph>
				          TimeKeeper manages your staff holidays for you. Our app allows your employees to request their holidays which require approval from their line manager. The line manager can approve or decline this with the touch of a button.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={leaveRequestSrc}
		    	imgAlt="Request annual leave for an employee"
		    	>
		    </LeftFeature>
		    <RightFeature 
		    	sectionTitle="Live Wall Calendar" 
		    	title="Your visual staff leave planner" 
		    	includeShadow={true}
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          If you have a large team or lots of staff, it can be easy to lose track of who is on holiday and when.
				        </FeaturePararaph>
				        <FeaturePararaph>
				          Refer to the live wall calendar to quickly view upcoming employee holidays so you can plan ahead or check if they have a birthday or work anniversary coming up.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={wallCalendarSrc} 
		    	imgAlt="Viewing staff absences on a wall calendar"/>

		    <LeftFeature 
		    	sectionTitle="Geofence Clock Entries" 
		    	title="Restrict clock entries by location" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          TimeKeeper enables you to configure geofences for jobs so only an employee at that location can clock in or out.
				        </FeaturePararaph>
				        <FeaturePararaph>
				          Simply specify an address for a job and a distance that the employee must be within and we will handle the rest.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={geofenceSrc}
		    	imgAlt="Geofence Areas on Clock In"
		    	 />
		    <RightFeature 
		    	sectionTitle="Who's In and Where" 
		    	title="Instantly check who is currently working" 
		    	includeShadow={true}
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          Do you want to know if your employees are where they are supposed to be?
				        </FeaturePararaph>
				        <FeaturePararaph>
				          GPS can be enabled to locate every clock in and out so that you can guarantee your employees are in the right place at the right time.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={whosInSrc}
		    	imgAlt="GPS Coordinates of a Clock In"
		    />
		    <LeftFeature 
		    	sectionTitle="Rota Planner" 
		    	title="Plan your shift rota online" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				        	You can schedule your staff on their shifts using the in-built rota planner!
				        	Are you tired of keeping a manual shift rota then having to make a change and notify the employee impacted?
				        </FeaturePararaph>
				        <FeaturePararaph>
				        	In TimeKeeper, you schedule the shifts and we take care of keeping the employees up to date!
				        	Also, employees can check their upcoming shifts anytime via our mobile app helping to reduce the chance of you being short-staffed.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={rotaPlannerSrc}
		    	includeShadow={true}
		    	imgAlt="Schedule Staff on a Rota Planner"
		    />
		    <RightFeature 
		    	sectionTitle="Live Employee Status" 
		    	title="Perform roll calls in emergencies" 
		    	includeShadow={true}
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          With TimeKeeper, you can check which employees are in and out either right now or historically via our web portal. 
				        </FeaturePararaph>
				        <FeaturePararaph>
				          This can be used in the event of a Health and Safety issue such as a fire in the office to allow for quick roll calls or just to check what the last job was they were working on.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={employeeStatusSrc}
		    	imgAlt="Live Inbuilding List Perfect for Fire List"
		    />
		    <LeftFeature 
		    	sectionTitle="Employee Instant Messenger" 
		    	title="Communicate with your employees in real-time" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          TimeKeeper has a built-in instant messenger on both web and mobile, allowing all your employees to communicate in real-time.
				        </FeaturePararaph>
				        <FeaturePararaph>
				          The instant messenger supports both individual and group based chat.
				          Your office staff can send messages via the web portal and employees in the field can write back via the TimeKeeper mobile app.


				        </FeaturePararaph>
				        <FeaturePararaph>
				        	<span className="italic">The instant messenger is a paid add-on available for £39/month (unlimited employees and unlimited messages)</span>
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={instantMessengerSrc}
		    	imgAlt="TimeKeeper Chat on Instant Messenger with Hillwood Group"
		    />
		    <RightFeature 
		    	sectionTitle="Integrated job scheduler" 
		    	title="Keep your employees on the right job" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				        	Are you constantly sending messages telling workers what job they are to meant work on?
				        </FeaturePararaph>
				        <FeaturePararaph>
				        	With TimeKeeper, you can also schedule your jobs in our web portal and we'll automatically notify your employees via a push notification of changes to their schedule.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={jobSchedulerSrc}
		    	imgAlt="Schedule Jobs"
		    />
		    <LeftFeature 
		    	sectionTitle="Accountancy/Payroll Integrations" 
		    	title="Integrate into your payroll process" 
		    	body={
		    		<div>
			    		<FeaturePararaph>
				          Avoid having to manually enter the same information from one system to another.
				        </FeaturePararaph>
				        <FeaturePararaph>
				          Send timesheets data from TimeKeeper into your accountancy software. Current integrations include Xero, Sage, MoneySoft and BrightPay.
				        </FeaturePararaph>
				    </div>
		    	}
		    	imgSrc={accountancyIntegrationsSrc}
		    	imgAlt="Payroll integration with Xero, Sage, MoneySoft and BrightPay"
		    />
		  </div>
		</div>
	</div>
)

export default FeatureSideBySide;