import React from "react"
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Hero from "../components/hero";
import Banner from '../components/banner';

import FeatureSideBySide from "../components/feature-side-by-side";
import {KGlackinTestimonial} from "../components/testimonial";
import FreeTrial from "../components/free-trial";
import Footer from "../components/footer";
import SEO from "../components/seo";
import FeatureOverview from "../components/features-overview";


const IndexPage = () => {
   
    return (
        <>
            <Layout>
                <SEO 
                	title="Simple Employee Timesheet App for UK and Irish Businesses | TimeKeeper"
                    description="Employee timesheets, time off, time spent on jobs, facial recognition, GPS geofences and much more."
                	 /> 
                <Navbar />
                <Hero />
                <FeatureOverview />
                <FeatureSideBySide />
                <KGlackinTestimonial />
                <FreeTrial showDemoBooking={true} includeDemo={true} />
                <Footer />
            </Layout>
        </>
    )
}

export default IndexPage
