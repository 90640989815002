import React from "react";

import abaxElectricalSrc from '../images/logos/abax-electrical.svg';
import polarIceSrc from '../images/logos/polar-ice.svg';
import kglackinSrc from '../images/logos/kglackin-electrics.svg';
import blkBoxSrc from '../images/logos/blk-box.svg';
import jandbEngineeringSrc from '../images/logos/j_and_b_engineering.svg';


const LogoCloud = () => (
	<div className="bg-white">
	  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
	    <p className="text-center text-base leading-6 uppercase text-gray-500 tracking-wider">
	      Trusted by these innovative businesses
	    </p>
	    <div className="bg-white">
		  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
		    <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
		      <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
		        <a href="https://www.glackinelectrics.co.uk" target="_blank" rel="noopener noreferrer"><img className="h-12" src={kglackinSrc} alt="Glackin Electrics" /></a>
		      </div>
		      <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
		        <a href="https://www.jandbengineering.co.uk" target="_blank" rel="noopener noreferrer"><img className="h-12" src={jandbEngineeringSrc} alt="J&B Engineering" /></a>
		      </div>
		       <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
		        <a href="https://www.blkboxfitness.com" target="_blank" rel="noopener noreferrer"><img className="h-12" src={blkBoxSrc} alt="BLK BOX" /></a>
		      </div>
		      <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
		        <a href="https://www.abaxelectrical.com/" target="_blank" rel="noopener noreferrer"><img className="h-12" src={abaxElectricalSrc} alt="ABax Electrical" /></a>
		      </div>
		      <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
		        <a href="https://polarice.ie" target="_blank" rel="noopener noreferrer"><img className="h-12" src={polarIceSrc} alt="Polar Ice" /></a>
		      </div>
		    </div>
		  </div>
		</div>
	  </div>
	</div>
)

export default LogoCloud;
